import styled from "styled-components"

export const TestimonialInfoContainer = styled.div`
  display: flex; 
  gap: 0.5rem;

  @media screen and (max-width: 768px) {
    align-items: center;
    gap: 0.25rem;
    /* max-height: 100px; */
  }
`

export const CustomerInfoText = styled.div`
  text-align: left;
  @media screen and (max-width: 768px) {
    width: 104px;
  }
`

export const CustomerInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media screen and (max-width: 768px) {
    flex-direction: row-reverse;
    gap: 0.25rem;
  }
`

export const Card = styled.div`
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 24px;
  max-width: 439px;

  @media screen and (max-width: 768px) {
    max-width: 360px;
    padding: 10px;
    margin-top: 12px;
  }
`

export const ProfileImgContainer = styled.div`
  width: 46px;
  height: 46px;

  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`

export const TestimonialImgContainer = styled.div`
  width: 389px;
  height: 210px;
  border-radius: 6px;

  @media screen and (max-width: 768px) {
    min-width: 132px;
    max-height: 100px;
  }
`