import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Card, CustomerInfoContainer, CustomerInfoText, ProfileImgContainer, TestimonialImgContainer, TestimonialInfoContainer } from "../../ClaimsTestimonial/SharedComponents"
import { SubTitle, Text } from "../../core/commonExports"
import { OnDesktop, OnMobile } from "../../DesktopMobileComps"

const ClaimTestimonial = () => {
  const customerInfo = (
    <CustomerInfoContainer>
      <ProfileImgContainer>
        <StaticImage 
          src="../../../assets/images/campaigns/bhaukal-juice-point-profile.webp"
          alt="bhaukal-juice-point-profile"
          loading="lazy"
          width={46}
          height={46}
        />
      </ProfileImgContainer>
      <CustomerInfoText>
        <Text fontSize="13px" mfontSize="10px" lineHeight="19px" mlineHeight="19px" fontWeight="bold" style={{margin: "0.25rem"}}>Vineet Mishra</Text>
        <Text fontSize="13px" mfontSize="10px" lineHeight="19px" mlineHeight="19px" fontWeight="bold" style={{margin: "0.25rem"}}><i>Juice seller, Varanasi</i></Text>
      </CustomerInfoText>
    </CustomerInfoContainer>
  )
  return (
    <>
      <SubTitle fontSize="30px" mfontSize="20px" lineHeight="36px" mlineHeight="24px" fontWeight="700" color="#2A2B7F" style={{textAlign: "left"}}>
        Hear from our customers about their claims
      </SubTitle>
      <Card>
        <TestimonialInfoContainer>
          <TestimonialImgContainer>
            <StaticImage 
              src="../../../assets/images/campaigns/testimonial-bhaukal-juice-point.webp"
              alt="bhaukal-juice-point"
              loading="lazy"
              width={389}
              height={210}
            />
          </TestimonialImgContainer>
          <OnMobile style={{width: "auto"}}>
            {customerInfo}
          </OnMobile>
        </TestimonialInfoContainer>
        <Text fontSize="14px" mfontSize="10px" lineHeight="22px" mlineHeight="16px" fontWeight="500" style={{textAlign: "left"}} desktopStyles={{marginTop: "1rem", marginBottom: "0.5rem"}} mobileStyles={{marginTop: "0.75rem"}}>
          Maine thode samay pehle Verak se vypaar suraksha policy li thi. Baarish ke kaaran mere juice point ki jo roof tut gayi thi. <strong>Maine Verak ke Whatsapp ke through claim file kia tha.</strong> Uske baad insurance company wale survey karne aaye the. Unne sab dekha. Mera claim paas hogya. <strong>Verak ki team ne bohut ache se mujhe guide aur support kia</strong>
        </Text>
        <OnDesktop>
          {customerInfo}
        </OnDesktop>
      </Card>
    </>
  )
}

export default ClaimTestimonial